<template>
  <TransferSuccessContent v-bind="componentProps" />
</template>

<script>
import { mapGetters } from 'vuex';

import CirclePaymentType from '@/enums/CirclePaymentType';
import TransferSuccessContent from '@/modules/walletv2/WalletModals/TransferSuccessContent/index.vue';

export default {
  name: 'TransferSuccess',
  props: {
    amount: {
      type: String,
      required: true,
    },
    depositStatus: {
      type: String,
      required: true,
    },
    method: {
      type: Object,
      required: true,
    },
    isDeposit: {
      type: Boolean,
      default: true
    },
  },
  components: {
    TransferSuccessContent
  },
  computed: {
    ...mapGetters('wallet', [
      'getBalance'
    ]),

    accountName() {
      switch (this.method.type) {
        case CirclePaymentType.CARD: return `${this.method.network} **** ${this.method.last4}`;
        default: return this.method.nickname || this.method.description;
      }
    },
    
    componentProps(){
      return {
        accountName: this.accountName,
        amount: this.numberFormat(this.amount, 2, false, true),
        balance: this.numberFormat(this.getBalance.buyingPower, 2, false, true),
        depositStatus: this.depositStatus,
        type: this.isDeposit ? 'deposit' : 'withdraw',
      };
    }
  }
};
</script>

<style scoped></style>
