<template>
  <div :class="modalClasses" role="dialog">

    <div :class="contentClasses">

      <div class="flex justify-center">
        <component :is="iconComponent" :style="iconStyle" />
      </div>

      <div class="flex flex-col gap-s32">

        <h3 class="headline-medium">
          <span class="text-text-positive">
            {{ amount }}
          </span>
          {{ titleByType }}
        </h3>

        <ul class="flex flex-col gap-s12">
          <li :key="info.label" v-for="info in content" class="flex justify-between">
            <p class="body-text-large">
              {{ info.label }}:
            </p>
            <p class="subheadline-x-small">
              {{ info.value }}
            </p>
          </li>
        </ul>

      </div>

      <ButtonV2
        class="capitalize"
        size="large"
        testId="btn-primary"
        :label="buttonLabel"
        @onClick="handleButton"
        wide
      />
      
    </div>

  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { IconSuccessSignLight, IconSuccessSignDark } from '@/assets/icons';
import CirclePaymentStatus from '@/enums/CirclePaymentStatus';

export default {
  name: 'TransferSuccessContent',
  components: {
    ButtonV2,
    IconSuccessSignLight,
    IconSuccessSignDark
  },
  props: {
    accountName: {
      type: String,
      required: true
    },
    amount: {
      type: String,
      required: true
    },
    balance: {
      type: String,
      required: true
    },
    depositStatus: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    titleByType(){
      return {
        deposit: this.depositStatus === CirclePaymentStatus.CONFIRMED ?
          this.$t('wallet.modal.success.title_deposit') :
          this.$t('wallet.modal.success.title_deposit_not_confirmed'),
        withdraw: this.$t('wallet.modal.success.title_withdraw')
      }[this.type];
    },
    buttonLabel(){
      return {
        deposit: this.depositStatus === CirclePaymentStatus.CONFIRMED ?
          this.$t('wallet.modal.success.start_trading') :
          this.$t('wallet.modal.success.view_pending_transactions'),
        withdraw: this.$t('wallet.modal.success.view_pending_transactions')
      }[this.type];
    },
    content(){
      return [
        {
          label: this.$t('wallet.balance'),
          value: this.balance,
        },
        {
          label: this.$t('wallet.account_name'),
          value: this.accountName,
        }
      ];
    },
    iconComponent(){
      return this.currentTheme === 'light' ? 'IconSuccessSignLight' : 'IconSuccessSignDark';
    },
    iconStyle(){
      return {
        width: '312px',
        height: '320px',
      };
    },
    modalClasses(){
      return {
        'modal-background': true,
      };
    },
    contentClasses(){
      return {
        'content': true,
        'content--large': !this.isMobileDevice,
        'content--small': this.isMobileDevice,
      };
    },
  },
  methods: {
    handleButton(){
      if (this.type === 'deposit') {
        switch (this.depositStatus) {
          case CirclePaymentStatus.CONFIRMED:
            this.goToInternalPageGlobal('/market');
            break;
          default:
            this.goToInternalPageGlobal('/wallet/transaction_history_all');
        }

        return this.hideModal();
      }

      this.goToInternalPageGlobal('/history');

      return this.hideModal();
    }
  }
};
</script>

<style scoped>
.modal-background {
  background: url(~@/assets/img/bg-wallet-success-modal-light.webp);
  background-size: cover;
  background-position: 0 -60px;
  background-repeat: no-repeat;
}

.dark .modal-background {
  background: url(~@/assets/img/bg-wallet-success-modal-dark.webp);
  background-repeat: no-repeat;
}

.content {
  @apply flex flex-col justify-between gap-s32;
}

.content--large {
  @apply py-s40 px-s20;
  max-width: 450px;
}

.content--small {
  @apply p-s20;
  height: 100%;
  width: 100%;
}
</style>
